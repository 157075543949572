<template>
  <main>
    <div>
      <div class="wrapper mb-8">
        <trac-back-button>
          <h2 class="text-xs">Integrations</h2></trac-back-button
        >

        <ul class="flex-col mt-12 text-xs">
          <li>Settings</li>
          <li class="font-bold mt-4 text-lg">Integrations</li>
        </ul>
      </div>

      <div class="big-shadow rounded-lg pb-6 mx-32">
        <div class="p-10">
          <h3 class="text-lg font-bold text-left mt-4">
            Quickbooks integration
          </h3>
          <hr class="my-1" />
        </div>

        <div class="big-shadow rounded-lg w-2/3 mx-auto flex justify-between">
          <div
              class="w-32 font-bold p-6 flex flex-col items-center mx-2"
            >
              <div class="bg-lightBlur p-2 rounded-sm flex items-center justify-center h-10">
                <img
                  :src="selectedItem.imgUrl"
                  class="w-6"
                  alt=""
                  srcset=""
                />
              </div>
              <p class="mt-2 text-xs text-center">{{ selectedItem.name }}</p>
            </div>

          <div class="flex items-center">
            <div
              class="bg-gray-500 p-1 mx-1 rounded-full inline"
              v-for="(dot, index) in 6"
              :key="index"
            ></div>
          </div>

          <div class="w-32 font-bold p-6 flex flex-col items-center mx-2">
            <div class="bg-lightBlur p-2 rounded-sm">
              <img
                src="https://staging.tractionapps.co/img/otherLogo.aa4c83cb.svg"
                alt=""
                srcset=""
              />
            </div>
            <p class="mt-2 text-xs text-center">Traction Apps</p>
          </div>
        </div>

        <div class="p-10">
          <h3 class="text-lg font-bold text-left mt-4">
            How the integration works?
          </h3>
          <div class=""></div>

          <hr class="my-8" />

          <p class="font-bold text-xs mb-2">Please note:</p>
          <ul>
            <!-- <li class="text-xs list-decimal ml-4 pl-2">
              Allow customers to earn extra cash
            </li> -->
            <li class="text-xs list-decimal ml-4 pl-2">
              Sync your day to day sales and transactions from Traction into Quickbooks
            </li>
            <li class="text-xs list-decimal ml-4 pl-2">
              Your information cannot be accessed by any third party
            </li>
          </ul>
        </div>

        <form
          action="https://quickbooks.tractionshop.co/auth"
          method="POST"
          class="flex justify-end mx-10"
        >
          <input
            type="text"
            name="app_name"
            id=""
            v-show="0"
            value="tractionapps"
          />
          <input type="text" name="app_id" id="" v-show="0" value="1" />
          <input
            type="text"
            name="operation"
            id=""
            v-show="0"
            value="connect"
          />
          <input
            type="text"
            name="request_hash"
            id=""
            v-show="0"
            value="D9552D79A5251F325A3D1275284E791FCB86F640AED5239A5787EF7727C146CB0F7F0F8E897AFFA7B840C3D1633ED540F9B383BF9C85683026D857A8AFCDFEC7"
          />
          <input
            type="text"
            name="business_id"
            id=""
            v-show="0"
            :value="business_id"
          />
          <input
            type="text"
            name="success_url"
            id=""
            v-show="0"
            :value="success_url"
          />
          <input
            type="text"
            name="error_url"
            id=""
            v-show="0"
            :value="error_url"
          />
          <trac-button buttonType="submit">Connect</trac-button>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
import { GET_LOCAL_DB_DATA, GET_USER_BUSINESS_ID } from "../../../browser-db-config/localStorage";
export default {
  data() {
    return {
      selectedItem: null,
      business_id: GET_USER_BUSINESS_ID(),
      success_url: window.location.origin + '/#/' + 'dashboard',
      error_url: window.location.origin + '/#/' + 'integrations',
    };
  },
  created() {
    this.selectedItem = GET_LOCAL_DB_DATA('selected-integration');
    if (!this.selectedItem) {
      this.$router.replace({ name: 'Integrations' });
    }
  },
  methods: {
    connectIntegration() {
      // const formData = new FormData();
      // formData.append("app_name", "tractionapps");
      // formData.append("app_id", 1);
      // formData.append("operation", "connect");
      // formData.append(
      //   "request_hash",
      //   "D9552D79A5251F325A3D1275284E791FCB86F640AED5239A5787EF7727C146CB0F7F0F8E897AFFA7B840C3D1633ED540F9B383BF9C85683026D857A8AFCDFEC7"
      // );
      // formData.append("business_id", GET_USER_BUSINESS_ID());
      // formData.append("success_url", "https://web.tractionapps.co/#/dashboard");

      // fetch(`https://quickbooks.tractionshop.co/auth`, {
      //   method: "POST",
      //   // body: formData,
      //   body: JSON.stringify({
      //     app_name: "tractionapps",
      //     app_id: 1,
      //     operation: "connect",
      //     request_hash:
      //       "D9552D79A5251F325A3D1275284E791FCB86F640AED5239A5787EF7727C146CB0F7F0F8E897AFFA7B840C3D1633ED540F9B383BF9C85683026D857A8AFCDFEC7",
      //     business_id: GET_USER_BUSINESS_ID(),
      //     success_url: "https://web.tractionapps.co/#/dashboard",
      //   }),
      // })
      //   .then((res) => res.json())
      //   .then((data) => {
      //     console.log(data);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    },
  },
};
</script>

<style>
</style>